@import url('./clockLoader.css');
@import url('./reactQuill.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-sans text-15 text-black;
  }
  button,
  input,
  textarea {
    @apply focus:outline-none;
  }

  * {
    @apply focus-visible:outline-none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

@font-face {
  font-family: 'BatangChe';
  src: local('BatangChe'), url('./fonts/BatangChe/BatangChe.ttf') format('truetype');
}
@font-face {
  font-family: 'RidiBatang';
  src: local('RidiBatang'), url('./fonts/RidiBatang/RIDIBatang.otf') format('truetype');
}

@font-face {
  font-family: 'NotoSerifKR';
  src: local('NotoSerifKR'),
    url('./fonts/NotoSerif/NotoSerifKR-Regular.otf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'NotoSerifKR';
  src: local('NotoSerifKR'),
    url('./fonts/NotoSerif/NotoSerifKR-Medium.otf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSerifKR';
  src: local('NotoSerifKR'),
    url('./fonts/NotoSerif/NotoSerifKR-Regular.otf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'NotoSerifKR';
  src: local('NotoSerifKR'),
    url('./fonts/NotoSerif/NotoSerifKR-SemiBold.otf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'NotoSerifKR';
  src: local('NotoSerifKR'),
    url('./fonts/NotoSerif/NotoSerifKR-Bold.otf') format('truetype');
  font-weight: 700;
}

@keyframes soft-blinking {
  0% {
    color: white;
  }
  50% {
    color: rgb(255, 255, 255, 0.75);
    filter: blur(0.5px);
  }
  100% {
    color: white;
  }
}

.animate-soft-blinking {
  -webkit-animation: soft-blinking 2.5s ease-out infinite;
  animation: soft-blinking 2.5s ease-out infinite;
}

.crossed {
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
}

img.ant-image-preview-img {
  display: inline-block;
}
.some-wrapper .react-timekeeper {
  --main-box-shadow: none;
  --main-bg: white;
  --clock-wrapper-bg: white;
  --clock-bg: #f3f4f6;
  --top-text-color: #1f2937;
  --numbers-font-size-inner: 18px;
  --numbers-font-size-outer: 16px;
  --numbers-text-color: black;
  --top-selected-color: #0ea5e9;
  --hand-circle-center: #7dd3fc;
  --hand-circle-outer: #7dd3fc;
  --hand-minute-circle: #7dd3fc;
}

@layer components {
  .label {
    @apply text-15 font-bold text-gray-800;
  }

  .label-col {
    @apply flex flex-col space-y-0.5;
  }

  .button {
    @apply flex cursor-pointer items-center justify-center whitespace-pre-line break-keep rounded-md border p-2 text-sm font-bold shadow-smd;
  }

  .icon-button {
    @apply center-box rounded-full hover:bg-gray-300 disabled:text-gray-300 disabled:hover:bg-inherit;
  }

  .button-fold {
    @apply button h-8 rounded-full px-[14px] text-xs theme-text-9 theme-bg-1 theme-border-1;
  }

  .button-no-border {
    @apply button rounded-none border-0 shadow-none;
  }

  .button-rectangle {
    @apply button button-no-border h-[52px] w-full;
  }

  .batang {
    font-family: 'BatangChe';
  }
  .bugo-tag {
    @apply center-box h-[28px] rounded-[14px] py-1 px-3 text-12 font-medium;
  }
  .checkbox {
    @apply wh-5 accent-myApricot;
  }
  .radioButton {
    @apply wh-4 border-gray-300 transition-colors duration-300 ease-out theme-text-main focus:outline-none focus:ring-white;
  }
  .center-box {
    @apply flex items-center justify-center;
  }
  .noto-serif-kr {
    font-family: 'NotoSerifKR';
  }
  .ridibatang {
    font-family: 'RidiBatang';
  }
  .bugo-h1 {
    @apply whitespace-pre-line break-keep text-base font-bold leading-5;
  }
  .bugo-h2 {
    @apply whitespace-pre-line break-keep text-sm font-bold leading-5;
  }
  .bugo-h3 {
    @apply whitespace-pre-line break-keep text-xs font-bold leading-5;
  }
  .input-box {
    @apply h-11 border border-gray-300 bg-white px-4 text-sm font-bold placeholder-gray-300
    transition-colors duration-300 ease-out hover:theme-border-main focus:ring-0 focus:theme-border-main disabled:bg-gray-100 disabled:text-gray-400 disabled:hover:border-gray-300;
  }
  .option-default {
    @apply border-b bg-white py-2 px-4 font-normal last:border-0 hover:cursor-pointer hover:bg-orange-100;
  }
  .date-picker {
    @apply input-box flex items-center px-2;
  }
  .textfield {
    @apply input-box;
  }
  .select {
    @apply input-box;
  }
  .warn {
    @apply border-warn;
  }
}

/* Antd table header color */
.ant-table-thead .ant-table-cell {
  background-color: transparent !important;
  color: inherit !important;
}

.ant-table-thead .ant-table-cell::before {
  background-color: transparent !important;
}
